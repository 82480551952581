<template>
    <div class="tram-order-list">
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
  </template>
  <script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
      data() {
        let _this = this;
        return {
          dialogVisible: false,
          opt: {
            title: "换电记录",
            search: [
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
                change(data) {
                    _this.getStoreList(data)
                }
              }
            },
            {
              key: "storeId",
              label: "门店名称",
              type: "remoteSearchById",
              opt: {
                list: []
              }
            },
              {
                key: "id",
                label: "换电编号",
              },
              {
                key: "date",
                label: "换电时间",
                type: "data-range"
              },
              {
                type: 'btnGroup',
                key: "btnGroup",
                buts: [{
                    type: "primary",
                    label: "今日",
                    timeType: 'day'
                  },
                  {
                    type: "primary",
                    label: "最近一周",
                    timeType: 'lastWeek'
                  },
                  {
                    type: "primary",
                    label: "本月",
                    timeType: 'month'
                  },
                  {
                    type: "primary",
                    label: "上月",
                    timeType: 'lastMonth'
                  }
                ]
              }
            ],
            columns: [{
                label: "换电编号",
                key: "id",
              },
              {
                label: "用户账号",
                key: "memberName",
              },
              {
                label: "手机号码",
                key: "mobile",
              }, 
              {
                label: "商家名称",
                key: "merchantName",
              },
              {
                label: "门店名称",
                key: "storeName",
              },
              {
                label: "换电电池",
                key: "itemName",
              },
              {
                label: "换电时间",
                key: "useTime",
              },
              {
                label: "换电结果",
                key: "stateStr",
              },
              {
                label: "结果描述",
                key: "resultStr",
              },
            ],
            // exportButtons: [{
            //   type: "Export",
            //   url: this.EXport + "/mall-service/orders/export",
            //   listName: '售货订单'
            // }],
          },
          merchantFlag: false,
        };
      },
      created() {
        console.log("salesOrder-list created!!");
  
      },
      async activated() {
        console.log("salesOrder-list activated!!");
        this.opt.search.forEach(item => {
          if (item.key == "btnGroup") {
            item.buts.forEach(it => {
              it.type = "primary";
            })
          }
        })
        try {
          this.merchantFlag = this.getLoginType('loginType');
          if (this.merchantFlag) {
            this.opt.search.forEach((item, index) => {
              if (item.key == 'merchantId') {
                this.opt.search.splice(index, 1);
              }
            })
            this.opt.columns.forEach((item, index) => {
              if (item.key == 'merchantName') {
                this.opt.columns.splice(index, 1);
              }
            })
          } else {
            let merchantList = await cabinetUtil.getMerchantList();
            this.opt.search.forEach((item, index) => {
              if (item.key == 'merchantId') {
                item.opt.list = merchantList;
              }
            })
          }
        } catch (error) {
          console.log(error)
        }
      },
  
      methods: {
        onGet(opt) {
          let dto = {
            pageNum: opt.skip,
            pageSize: opt.limit,
            ...opt.searchForm,
            startTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
            endTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") :
              null,
          };
          this.post("mall-service/bc/change/battery/page", dto, {
            isUseResponse: true
          }).then(res => {
            res.data.data = res.data.list;
            res.data.list.forEach(item => {
              if(item.state == 0) {
                item.stateStr = '成功'
                item.resultStr = '开锁成功'
              } else {
                item.stateStr = '失败'
              }
              if(item.state == 1) {
                item.resultStr = '柜门弹开失败'
              } else if(item.state == 2){
                item.resultStr = '连接从柜门失败'
              } else if(item.state == 5){
                item.resultStr = '柜门已经是打开状态'
              } else if(item.state == 6){
                item.resultStr = '蓝牙芯片通讯失败'
              } else if(item.state == 30){
                item.resultStr = '其他错误'
              } else if(item.state == 100){
                item.resultStr = '设备未响应开门结果'
              } else if(item.state == 101){
                item.resultStr = '并发场景未处理的开门请求'
              } else if(item.state == 11){
                item.resultStr = '秘钥校验失败'
              }
              item.merchantName = item.merchantName ? item.merchantName : '--';
            });
            opt.cb(res.data);
          });
          if(opt.searchForm.merchantId) {
              this.getStoreList(opt.searchForm.merchantId);
          }else{
              this.getStoreList();
          }
        },
        //获取门店列表
        getStoreList(data) {
            let dto = {
                currentPage: 1,
                size: 99999,
            };
            if(data) {
                dto.merchantId = data
            }
            this.post("/mall-service/v1/counterStores/getAllList", dto, {
                isUseResponse: true,
            }).then(res => {
                let storeList = res.data.data;
                storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
                storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
                this.opt.search.forEach(item => {
                    if (item.key == 'storeId') {
                    item.opt.list = storeList;
                    }
                })
            });
        },
      }
    };
  </script>
  <style lang="scss" scoped>
    /deep/.el-table td:last-child {
      padding-right: 16px
    }
  </style>